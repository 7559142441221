import Mixins from '../../vue-elements/comparison-lib/mixins';
import { createPrivateLeaseComparerApp } from '../../../assets/js/ssrHelpers';

export default function registerPrivateLeaseComparer() {
    // Get all compare-module dom elements
    const compareDomElements = Array.from(document.getElementsByClassName('private-lease-comparison-module-container'));
    if (!compareDomElements) {
        return;
    }

    compareDomElements.forEach((domElement) => {
        // Loop through dom elements and create new Vue instance for each individual comparer module
        let props = {};
        const config = window.plComparerData;
        if (config) {
            props = { config };
        } else {
            props = { ...domElement.dataset };
        }

        console.log(props);
        const PrivateLeaseComparisonModule = createPrivateLeaseComparerApp(props);
        PrivateLeaseComparisonModule.mixin(Mixins());

        PrivateLeaseComparisonModule.mount(domElement);
    });
}
